@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: 'Roboto', sans-serif;
}

h2 {
  @apply text-gray-100 text-3xl font-bold mb-4;
}

button {
  @apply transition-transform duration-200 transform hover:scale-105;
}

input {
  @apply w-full px-4 py-2 mt-3 text-gray-900 rounded shadow-sm focus:ring-accent focus:border-accent;
}
