body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
}

.App {
  text-align: center;
}

.container {
  max-width: 768px;
  margin: 0 auto;
  padding: 1rem;
}

header {
  display: flex;
  justify-content: center;
  align-items: center;
}
